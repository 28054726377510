import React, {useState} from "react";
import SeatIcon from "./icons/Seat";
import classNames from "classnames";
import {addSeat, removeSeat} from "../actions/reservedSeats";
import {useDispatch} from "react-redux";

export default function Seat(props) {
    const { carriage, number, announcedNumber, firstClass, available, active, reverse, compartmentTop, compartmentBottom } = props;

    const dispatch = useDispatch();
    const [isActive, setActive] = useState(active);

    const toggleActive = () => {
        if (! available) {
            return;
        }

        if (isActive) {
            dispatch(removeSeat(carriage, number))
        } else {
            dispatch(addSeat(carriage, number, announcedNumber, firstClass))
        }

        setActive(!isActive);
    }

    return (
        <button key={number} type="button" className={classNames("seat", {"available": available, "reverse": reverse, "active": isActive, "compartment-top": compartmentTop, "compartment-bottom": compartmentBottom, "cursor-default": !available})} onClick={toggleActive} >
            <SeatIcon />
            <span className="seat-number-single">{number}</span>
        </button>
    )
}
